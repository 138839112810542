import monitoring from './monitoring';

export const createAddPage = membersAPI => async (
  interactionName,
  {
    appDefinitionId,
    pageId,
    method,
    loginMenuTitle,
    menuOrder,
    numbers = {},
    social = true,
    showInLoginMenu = false,
    showInMemberMenu = true,
    socialHome = false,
    urlOverride = null,
    visibleForRoles = [],
  },
) =>
  monitoring.toMonitored(
    interactionName,
    membersAPI[method]({
      appDefinitionId,
      pageId,
      loginMenuTitle,
      social,
      showInLoginMenu,
      numbers,
      menuOrder,
      showInMemberMenu,
      socialHome,
      urlOverride,
      visibleForRoles,
    }),
  );

export default { createAddPage };

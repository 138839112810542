import { EXPERIMENT_IS_PAGES_PANEL_ENABLED } from '@wix/communities-blog-experiments';
import experiments from './experiments';
import actions from './actions';
import postPageMigration from './post-page-migration';
import oldBlogMigration from './old-blog-migration';
import menu from './menu';
import pageService from './page';
import {
  MANAGE_POSTS_EVENT,
  SITE_WAS_FIRST_SAVED,
  SITE_WAS_PUBLISHED,
  DELETE_BLOG_EVENT,
  WIDGET_GFPP_CLICKED,
  COMPONENT_GFPP_CLICKED,
  OPEN_WIDGET_MAIN_SETTINGS,
  OPEN_WIDGET_LAYOUT_SETTINGS,
} from '../constants/events';
import getAppManifest from './app-manifest';
import { OFFLINE_POST_PAGE_MIGRATION_ID } from '../constants/migrations';
import { TPA_PAGE_ID_BLOG } from '../constants/tpa-pages';
import translation from './translation';
import translations from '../translations.json';

export const handleEditorReady = async context => {
  await experiments.conduct();
  const locale = await context.sdk.environment.getLocale();
  translation.init(translations, locale);

  return context.isADI
    ? postPageMigration.prepare(context)
    : Promise.all([
        actions.setDependantApps(context),
        actions.installMissingPostPage(context),
        postPageMigration.prepare(context),
        menu.addPages(context),
      ]);
};

export const handleBlogInstalled = async ({ sdk, appToken, isADI }) => {
  await actions.initProvision({ sdk });

  const [membersApi] = await Promise.all([
    actions.getMembersApi({ sdk, appToken }),
    actions.initBiService({ sdk, appToken }),
  ]);
  const isMembersAreaInstalled = Boolean(membersApi);

  const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({ sdk, isADI });
  if (shouldMigrateOldBlog) {
    return Promise.all([
      isMembersAreaInstalled ? actions.installBlogSections({ sdk, appToken }) : Promise.resolve(),
      oldBlogMigration.migrate({ sdk }),
    ]);
  }

  return isMembersAreaInstalled
    ? actions.installBlogSections({ sdk, appToken })
    : actions.displayProvisioningModal({ sdk, appToken, isADI });
};

export const handleBlogInstalledWithoutMembersArea = async ({ sdk, appToken, isADI }) => {
  await actions.initProvision({ sdk });
  const shouldMigrateOldBlog = await oldBlogMigration.shouldMigrate({ sdk, isADI });

  return Promise.all([
    actions.installBlogSections({ sdk, appToken }),
    shouldMigrateOldBlog ? oldBlogMigration.migrate({ sdk }) : Promise.resolve(),
  ]);
};

export const handleGFPPClicked = ({ id, componentRef, context, componentRole }) => {
  if (id === OPEN_WIDGET_MAIN_SETTINGS) {
    actions.openMainSettings(context, componentRef, componentRole);
  }

  if (id === OPEN_WIDGET_LAYOUT_SETTINGS) {
    actions.openLayoutSettings(context, componentRef, componentRole);
  }
};

export const handleOnEvent = async ({ eventType, eventPayload }, context) => {
  switch (eventType) {
    case SITE_WAS_FIRST_SAVED:
      return actions.installMissingPostPage({ ...context, instance: eventPayload.instance });
    case SITE_WAS_PUBLISHED:
      return postPageMigration.start(context);
    case MANAGE_POSTS_EVENT:
      return (
        context.sdk &&
        context.sdk.editor.openDashboardPanel(context.appToken, {
          url: 'blog',
          closeOtherPanels: false,
        })
      );
    case DELETE_BLOG_EVENT:
      const blogPage = await pageService.find({ ...context, tpaPageId: TPA_PAGE_ID_BLOG });
      return context.sdk.document.pages.remove(context.appToken, { pageRef: { id: blogPage.id } });
    case WIDGET_GFPP_CLICKED: {
      const { id, componentRef } = eventPayload;
      return handleGFPPClicked({ id, componentRef, context });
    }
    case COMPONENT_GFPP_CLICKED: {
      // componentRole would be used creating widget's inner component's settings panels
      const { id, controllerRef, role } = eventPayload;
      return handleGFPPClicked({ id, componentRef: controllerRef, context, componentRole: role });
    }
    default:
      break;
  }
};

export const handleGetAppManifest = ({ sdk, appToken }) => {
  const appManifestEnabled = experiments.isEnabled(EXPERIMENT_IS_PAGES_PANEL_ENABLED);
  if (!appManifestEnabled || !sdk) {
    return {};
  }

  return getAppManifest(sdk.info.getSdkVersion().scriptSrc, appToken);
};

export const handleMigrateAction = (context, { migrationId } = {}) => {
  switch (migrationId) {
    case OFFLINE_POST_PAGE_MIGRATION_ID:
      return postPageMigration.migrateOffline(context);
    default:
      return Promise.resolve();
  }
};

export default {
  handleBlogInstalled,
  handleBlogInstalledWithoutMembersArea,
  handleEditorReady,
  handleOnEvent,
  handleGetAppManifest,
  handleMigrateAction,
};
